import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridRenderCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { AsyncDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { GridActionsCellItemLink } from '../../components/FakeLink';
import { ProtectedContent } from '../../components/ProtectedContent';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanUsers, fetchUsers, updateUserStatus } from '../../redux/modules/user/user.actions';
import { userSelector } from '../../redux/modules/user/user.selectors';
import { locations } from '../../routes/locations';
import { User, UserStatus } from '../../types/user';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';
import { StatusReasonDialog } from './StatusReasonDialog';

const userStatusToInfo: {
  [prop in UserStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [UserStatus.REGISTERED]: {
    color: 'info',
    menuAction: 'Change to Registered',
    icon: InfoIcon,
  },
  [UserStatus.ACTIVE]: {
    color: 'success',
    menuAction: 'Activate',
    icon: CheckCircleIcon,
  },
  [UserStatus.PENDING]: {
    color: 'info',
    menuAction: 'Change to Pending',
    icon: InfoIcon,
  },
  [UserStatus.INACTIVE]: {
    color: 'warning',
    menuAction: 'Deactivate',
    icon: WarningIcon,
  },
  [UserStatus.MANUAL_CHECK_REQUIRED]: {
    color: 'warning',
    menuAction: 'Manual Check Required',
    icon: WarningIcon,
  },
  [UserStatus.WAITING_ALPACA_ACTIVATION]: {
    color: 'warning',
    menuAction: 'Waite Alpaca Activation',
    icon: WarningIcon,
  },
  [UserStatus.BLOCKED]: {
    color: 'error',
    menuAction: 'Block',
    icon: RemoveCircleIcon,
  },
};

const availableStatusesToUpdate: { [prop in UserStatus]: UserStatus[] } = {
  [UserStatus.REGISTERED]: [UserStatus.PENDING],
  [UserStatus.ACTIVE]: [UserStatus.INACTIVE, UserStatus.BLOCKED],
  [UserStatus.PENDING]: [UserStatus.ACTIVE, UserStatus.BLOCKED],
  [UserStatus.INACTIVE]: [UserStatus.ACTIVE, UserStatus.BLOCKED],
  [UserStatus.BLOCKED]: [],
  [UserStatus.MANUAL_CHECK_REQUIRED]: [],
  [UserStatus.WAITING_ALPACA_ACTIVATION]: [],
};

export function UserList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef<TableRef>(null);
  const asyncDialogRef = useRef<AsyncDialogRef>(null);
  const [selectedRow, setSelectedRow] = useState<User>();
  const [actionMenu, setActionMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'nationalIdNumber',
      headerName: 'National Id',
      type: 'number',
      width: 150,
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      type: 'string',
      width: 150,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      type: 'string',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(UserStatus),
      minWidth: 120,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = userStatusToInfo[params.row?.status?.name as UserStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'group.nameEn',
      headerName: 'Group',
      type: 'string',
      minWidth: 120,
      valueGetter: (params) => params.row?.group?.nameEn,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const canRead = usePermissions({ items: [permissions.User.read] });
  const canDoActions = usePermissions({ items: [permissions.User.statusUpdate] });
  const { totalUsers, users, fetchStatus, updateStatusStatus } = useSelector(userSelector);
  const updateStatusLoading = updateStatusStatus === fetchStatuses.pending;
  const loading = fetchStatus === fetchStatuses.pending;
  const availableStatuses = (selectedRow?.status?.name && availableStatusesToUpdate[selectedRow.status.name]) || [];
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialSortModel, setInitialSortModel] = useSearchParamsState(
    'sort',
    JSON.stringify([{ field: 'id', sort: 'desc' }]),
  );
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, data: User) => {
    setSelectedRow(data);
    setActionMenu(actionMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };

  const handleCloseMenu = () => {
    setActionMenu(null);
  };

  const updateStatus = async (user: User, status: UserStatus) => {
    try {
      let disableReason;
      let approvalReason;

      if ([UserStatus.BLOCKED, UserStatus.INACTIVE].includes(status)) {
        disableReason = await asyncDialogRef?.current?.show();
      }

      if (status === UserStatus.ACTIVE && user?.status.name === UserStatus.MANUAL_CHECK_REQUIRED) {
        approvalReason = await asyncDialogRef?.current?.show();
      }

      dispatch(updateUserStatus({ id: user.id, status, disableReason, approvalReason }));
    } catch (e) {
      enqueueSnackbar(`The reason is required for status ${status}`, { variant: 'error' });
    }
    handleCloseMenu();
  };

  const onAddNew = (): void => {
    navigate(locations.user(0));
  };

  useEffect(
    () => () => {
      dispatch(cleanUsers());
    },
    [],
  );

  useEffect(() => {
    if (updateStatusStatus === fetchStatuses.success) {
      tableRef?.current?.reload();
      enqueueSnackbar('User status updated!', { variant: 'success' });
    }
    if (updateStatusStatus === fetchStatuses.rejected) {
      enqueueSnackbar('User update status error!', { variant: 'error' });
    }
  }, [updateStatusStatus]);

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canRead) {
          actions.push(
            <GridActionsCellItemLink
              key={`user-list-read-${params.row.id}`}
              icon={<OpenInNewIcon />}
              label="Link"
              href={locations.user(params.row.id)}
            />,
          );
        }

        if (canDoActions) {
          actions.push(
            <GridActionsCellItem
              key={`employee-list-actions-${params.row.id}`}
              icon={<MoreVertIcon />}
              label="Actions"
              onClick={(e) => handleMenuOpen(e, params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canRead || canDoActions) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canRead, canDoActions]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Users &nbsp;
          <ProtectedContent items={[permissions.User.create]}>
            <Fab color="primary" size="small" aria-label="Create User" onClick={onAddNew}>
              <AddIcon />
            </Fab>
          </ProtectedContent>
        </Typography>
      </Box>
      <Table
        ref={tableRef}
        fetchItems={fetchUsers}
        rows={users}
        columns={columns}
        loading={loading}
        rowCount={totalUsers}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialSortModel={parseJSON(initialSortModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onSortModelChange={(sortModel) => setInitialSortModel(JSON.stringify(sortModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
      />
      <Menu
        open={actionMenu !== null && !!availableStatuses?.length}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={actionMenu !== null ? { top: actionMenu.mouseY, left: actionMenu.mouseX } : undefined}
      >
        {availableStatuses.map((status) => {
          const info = userStatusToInfo[status] || {};

          return (
            <MenuItem
              key={`user-list-menu-action-${status}`}
              onClick={() => selectedRow && updateStatus(selectedRow, status)}
              disabled={updateStatusLoading}
            >
              {info.menuAction}
            </MenuItem>
          );
        })}
      </Menu>
      <AsyncDialog ref={asyncDialogRef} dialog={StatusReasonDialog} />
    </Container>
  );
}
